<template>
 <div class="s_user_cash_out_list_pop">
    <el-dialog :title="'查看资金变动记录(近30天)'" class="s-task-dialog" top="30px" width="1100px" :close-on-click-modal="false" :visible.sync="show">
        <el-form label-width="140px" size="mini">
            <!-- 资金变动记录 -->
          <el-table v-loading="table.loading" class="g-self-scrollbar s-user-cash-ou-list-pop-table" ref="singleTable"  size="small" :data="table.rows" highlight-current-row  style="width: 100%">
            <el-table-column prop="user_id" label="用户ID" width="120" :show-overflow-tooltip="true"/>
            <el-table-column prop="key_id" label="记录编号" width="120" :show-overflow-tooltip="true"/>
            <el-table-column label="资金类别" width="100" :show-overflow-tooltip="true">
              <template  slot-scope="scope">
                <span class="g-fsize14 g-red" v-show="scope.row.category === 1">本金结算</span>
                <span class="g-fsize14 g-green" v-show="scope.row.category === 2">佣金结算</span>
              </template>
            </el-table-column>

            <el-table-column label="类型" width="100" :show-overflow-tooltip="true">
              <template  slot-scope="scope">
                <span class="g-fsize14">{{scope.row.type | filtersType }}</span>
              </template>
            </el-table-column>
            <el-table-column label="标题" :show-overflow-tooltip="true">
              <template  slot-scope="scope">
                <span class="g-fsize14">{{scope.row.title}}</span>
              </template>
            </el-table-column>
            <el-table-column label="变动金额" width="160" :show-overflow-tooltip="true">
              <template  slot-scope="scope">
                <span class="g-fsize14 g-yellow">{{scope.row.money}}</span>
              </template>
            </el-table-column>
            <el-table-column label="变更后余额" width="180" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <span class="g-fsize14 g-yellow">{{scope.row.balance}}元</span>
                </template>
            </el-table-column>
          </el-table>

          <el-pagination :page-sizes="[15, 30, 60, 100]" :page-size="query.limit" :total="table.total" :current-page.sync="query.page" @current-change="getList(false)" @size-change="sizeChange" small hide-on-single-page background layout="total, sizes, prev, pager, next, jumper"></el-pagination>

        </el-form>

        <div slot="footer" class="dialog-footer">
          <el-button @click="show = false" >取 消</el-button>
          <el-button type="primary" @click="show = false">确定</el-button>
        </div>
    </el-dialog>

 </div>
</template>

<script>
import { formatDate } from '@/utils/index.js'
import { apiGetUserMoneyDay30 } from '@/utils/api.js'
export default {

  data() {
    return {
      query: {
        page:1, limit: 15, user_id: ''
      },

      table:{ loading:false, total:0, rows:[] },

      show: false,
    }
  },
  methods: {
    formatDate,
    // 近30天资金记录
    async getList(pageInit = true) {
      if (pageInit) this.query.page = 1
      this.table.loading = true
      const { success, data } = await apiGetUserMoneyDay30(this.query)
      if(!success) return
      this.table.loading = false
      this.table.rows = data.rows
      this.table.total = data.total
      console.log(data)
    },
    //每页数量改变
    sizeChange(limit) {
      this.query.limit = limit
      this.getList()
    },
    onShow(data) {
      console.log(data)
      this.query.user_id = data.id
      this.getList(data)
      this.show = true
    },
    onClose() {
      this.show = false
    }
  },
  filters: {
      filtersType(type) {
        if(type == 1) return '充值'
        if(type == 2) return '提现'
        if(type == 3) return '任务'
        if(type == 4) return '订单'
        if(type == 5) return '推广'
        return '其他'
      }
    },
}
</script>

<style lang='scss'>
.s_user_cash_out_list_pop {
  .el-dialog {
    max-height: 920px;
    margin: 0 auto;
  }
  .s-tabs-content-two {
    padding-bottom: 20px;
  }
  .s-user-cash-ou-list-pop-table {
    height: 500px;
    overflow: auto;
  }
  .s-user-remark {
    margin-top: 10px;
  }
}
</style>