<template>
 <div class="s_user_list_pop">
    <el-dialog :close-on-press-escape="false" :title="seeStatus == 1 ? '新增代理': '编辑代理'" class="s-task-dialog" top="15vh" width="800px" @close="onClose" :close-on-click-modal="false" :visible.sync="show">
      <el-form :model="formData" label-width="140px" size="mini">
         <el-row v-if="formData.id">
          <el-col :span="12">
            <el-form-item label="代理ID:">
              <span>{{ formData.id }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        
        <el-row>
          <el-col :span="12">
            <el-form-item label="代理名字:">
              <el-input placeholder="请输入代理名字" v-model="formData.name"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="代理手机号:">
              <el-input  oninput="value=value.replace(/[^0-9.]/g,'')" placeholder="请输入代理手机号" v-model="formData.mobile"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="代理登录密码:">
               <el-input placeholder="不修改不填写此项" v-model="formData.password" show-password></el-input>
              <span class="g-red">6-20的数字字母组合</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="代理抽点（%）:">
              <el-input oninput="value=value.replace(/[^0-9.]/g,'')" placeholder="请输入代理抽点" v-model="formData.reach_rate"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="onClose">取 消</el-button>
        <el-button type="primary" @click="apiSaveAgentHandel">确定</el-button>
      </div>
    </el-dialog>
 </div>
</template>

<script>
import { formatDate } from '@/utils/index.js';
import { apiSaveAgent } from '@/utils/api.js'
export default {
  data() {
    return {
      seeStatus: 1,
      show: false,
      formData: {
        id: '',
        name: '',
        mobile: '',
        password: '',
        reach_rate: ''
      },
    }
  },
  methods: {
    formatDate,
    // status //1 新增 2编辑
    onShow(data, status) {
      this.seeStatus = status
      this.formData = Object.assign({}, this.formData, data)
      this.show = true
    },
    
    // 增加 编辑 代理
    async apiSaveAgentHandel() {
      let loading = this.$loading()
      const { success, data } = await apiSaveAgent(this.formData).catch(() => loading.close())
      loading.close()
      if(!success) return
      this.$message.success(data.msg)
      this.$emit('emitSaveSuccess')
      this.onClose()
    },
    onClose() {
      this.show = false
      this.formData = {
        id: '',
        name: '',
        mobile: '',
        password: '',
        reach_rate: ''
      }
    }
  }
}
</script>

<style lang='scss'>

</style>