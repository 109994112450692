<template>
  <el-card class="v_user_list">
    <div class="g-flex"  slot="header">
      <span>代理列表</span>
      <div class="g-flex-justify-end g-flex-1">
        <el-button size="mini" type="success" @click="setForm({id:''}, 1)">新增下级代理</el-button>
      </div>
    </div>
    <!-- 搜索 -->
    <el-form :inline="true" size="mini" :model="query">
      <el-form-item label="代理状态">
        <el-select v-model="query.status" @change="getList()">
          <el-option label="全部" value="ALL"></el-option>
          <el-option label="正常" value="1"></el-option>
          <el-option label="禁用" value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="推广码:">
        <el-input v-model="query.spread_code" @keyup.enter.native="getList()" @clear="getList()" placeholder="请输入推广码" clearable></el-input>
      </el-form-item> 
      <el-form-item label="代理手机号:">
        <el-input v-model="query.mobile" @keyup.enter.native="getList()" @clear="getList()" placeholder="请输入代理手机号" clearable></el-input>
      </el-form-item> 
      <el-form-item>
        <el-select slot="label" v-model="query.search_key" placeholder="查询">
          <el-option label="代理ID" value="user_id"></el-option>
          <el-option label="姓名" value="name"></el-option>
        </el-select>
        <el-row>
          <el-col :span="14">
            <el-input v-model="query.search_val" @keyup.enter.native="getList()" @clear="getList()" placeholder="请输入查找内容" clearable></el-input>
          </el-col>
          <el-col :span="4" :offset="1">
            <el-button type="info" @click="resetData">重置</el-button>
          </el-col>
          <el-col :span="4" :offset="1">
            <el-button type="primary" @click="getList()">查询</el-button>
          </el-col>

        </el-row>
      </el-form-item>
    </el-form>

    <!-- 表格 -->
    <el-table :row-class-name="tableRowClassName"  size="mini" v-loading="table.loading" :data="table.rows" border>
      <el-table-column label="代理手机号" width= "110">
          <template slot-scope="scope">
              <span>{{ scope.row.mobile }}</span>
          </template>
      </el-table-column>
      <el-table-column label="代理id">
          <template slot-scope="scope">
              <span>{{scope.row.id}}</span>
          </template>
      </el-table-column>
      <el-table-column label="代理名字" width= "150" :show-overflow-tooltip="true">
          <template slot-scope="scope">
              <span>{{scope.row.name }}</span>
          </template>
      </el-table-column>
      <el-table-column label="代理抽点" width= "150" :show-overflow-tooltip="true">
          <template slot-scope="scope">
              <span class="g-blue">{{scope.row.reach_rate }} % </span>
          </template>
      </el-table-column>
      
      <el-table-column label="余额" width= "150">
        <template slot-scope="scope">
          <div  class="g-flex-align-center v-user-money-item-edit">
            <span class="g-red">{{ scope.row.principal }}</span>
            <i class="el-icon-edit" @click="changeMoneyClick(scope.row, 1)"></i>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="直属上级" width="150" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <div class="g-flex-align-center v-user-money-item-edit">
            <span class="g-red">{{ scope.row.spread | filtersSpread }}</span>
          </div>
          </template>
      </el-table-column>
      <el-table-column label="直属上级推广码" width="150" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <div class="g-flex-align-center v-user-money-item-edit">
            <span class="g-red">{{ scope.row.spread.spread_code }}</span>
          </div>
          </template>
      </el-table-column>
      <el-table-column label="本人推广码" width="100" :show-overflow-tooltip="true">
        <template slot-scope="scope">
              <span>{{ scope.row.spread_code }}</span>
          </template>
      </el-table-column>
      <el-table-column label="直属下级人数" width="100" :show-overflow-tooltip="true">
        <template slot-scope="scope">
              <span>{{ scope.row.spread_nums }}</span>
          </template>
      </el-table-column>
      <el-table-column label="状态" width="80" :show-overflow-tooltip="true">
        <template slot-scope="scope">
            <span class="g-red" v-if="scope.row.status == 0">禁用 </span>
            <span class="g-green" v-if="scope.row.status == 1 && (scope.row.black_time < parseInt(new Date().getTime() / 1000))">正常</span>
            <span class="g-green" v-if="scope.row.status == 1 && (scope.row.black_time > parseInt(new Date().getTime() / 1000))">正常 (到期时间: {{formatDate(scope.row.black_time,'yyyy-MM-dd hh:mm')}})</span>
          </template>
      </el-table-column>
      <el-table-column label="创建时间" :show-overflow-tooltip="true">
          <template slot-scope="scope">
              <span>{{formatDate(scope.row.create_time, 'yyyy-MM-dd hh:mm')}}</span>
          </template>
      </el-table-column>
      <el-table-column label="操作" width="250" fixed="right">
        <template slot-scope="scope">
          <el-button size="mini" type="danger" @click="setForm(scope.row, 2)">编辑</el-button>
          <el-button size="mini" type="success" @click="seeMoneyHistoryClick(scope.row)">资金变动记录</el-button>
          <el-button size="mini" type="info" @click="apiUserAddPianziHandel(scope.row, 0)" v-if="scope.row.status == 1">封号</el-button>
          <el-button size="mini" type="primary" @click="apiUserAddPianziHandel(scope.row, 1)" v-if="scope.row.status == 0">解封</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 弹窗 -->
    <dai-li-list-pop ref="DaiLiListPop" @emitSaveSuccess="getList(false)"/>

    <!-- 屏蔽 -->
    <dai-li-list-pingbi-time-pop ref="DaiLiListPingbiTimePop" @emitSaveSuccess="getList(false)"/>

    <el-pagination :page-sizes="[15, 30, 60, 100]" :page-size="query.limit" :total="table.total" :current-page.sync="query.page" @current-change="getList(false)" @size-change="sizeChange" small hide-on-single-page background layout="total, sizes, prev, pager, next, jumper"></el-pagination>
    
    <dai-li-list-change-money-pop ref="DaiLiListChangeMoneyPop" @emitSaveSuccess="getList(false)"/>

    <dai-list-money-history-pop ref="DaiListMoneyHistoryPop"/>
  </el-card>
</template>

<script>
  import { formatDate } from '@/utils/index.js';
  import { apiGetAgentList, apiSetAgentStatus } from '@/utils/api.js'
  import DaiLiListPop from './DaiLiListPop.vue'
  import DaiLiListPingbiTimePop from './DaiLiListPingbiTimePop.vue'
  import DaiLiListChangeMoneyPop from './DaiLiListChangeMoneyPop.vue'
  import DaiListMoneyHistoryPop from './DaiListMoneyHistoryPop.vue'

  import { mixins } from '@/utils/mixins.js'
  export default {
    name: 'userList',
    mixins: [mixins],
    components: { DaiLiListPop, DaiLiListPingbiTimePop, DaiLiListChangeMoneyPop, DaiListMoneyHistoryPop },
    data() {
      return {
        auth:{
          addBlack: this.$global.ApiList.includes(60),
          delBlack: this.$global.ApiList.includes(61),
        },

        query: { search_key: 'name', search_val: '', mobile: '', spread_code: '', date_time: [], status: 'ALL', page:1, limit: 15 },

        table:{ loading:false, total:0, rows:[] },

        form: false,
        radio: 1,
        areaCodeSelected: [],

        formData: {},

        commissionShow: true,
        principalShow: true,

      }
    },
    created() {
      this.getList()
    },
    activated() {
      console.log('activated')
    },
    inactivated() {
      console.log('inactivated')
    },
    filters: {
      filtersSex(sex) {
        if(sex == 1) return '男'
        return '女'
      },
      filtersSpread(obj) {
        if(!obj.id) return '无'
        if(obj.type == 1) return `买家-${obj.mobile}`
        if(obj.type == 2) return `商家-${obj.mobile}`
        if(obj.type == 3) return `代理-${obj.mobile}`
        return ''
      }
    },
    methods: {
      formatDate,
      // 查看资金变动记录
      seeMoneyHistoryClick(item) {
        this.$refs.DaiListMoneyHistoryPop.onShow(item)
      },
       //1 修改本金余额 2修改佣金余额
      changeMoneyClick(item,status) {
        this.$refs.DaiLiListChangeMoneyPop.onShow(item, status)
      },

      // 点击封号
      async apiUserAddPianziHandel(item, status) {
        let str = status == 0 ? '封号': '解封'
        this.$confirm(`此操作将该用户${str}, 是否继续?`, '封号', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          this.table.loading = true
          const { success, data } = await apiSetAgentStatus({ id: item.id, status: status}).catch(() =>  this.table.loading = false)
          this.table.loading = false
          if(!success) return
          this.$message.success(data.msg)
          this.getList(false)
        }).catch(()=>{})
      },


      // 加入黑名单
      addBlack(item, status) {
        this.$refs.DaiLiListPingbiTimePop.onShow(item, status)
      }, 

      async getList(pageInit = true) {
        if (pageInit) this.query.page = 1
        this.table.loading = true
        const { success, data } = await apiGetAgentList(this.query).catch(() => this.table.loading = false)
        if(!success) return
        this.table.loading = false
        this.table.rows = data.rows
        this.table.total = data.total
      },

      //每页数量改变
      sizeChange(limit) {
        this.query.limit = limit
        this.getList()
      },

      //设置form
      setForm(formData = { id: '' }, status) {
        this.$refs.DaiLiListPop.onShow(formData, status)
      },
      
      // 点击重置
      resetData() {
        this.query = { search_key: 'name', search_val: '', mobile: '', spread_code: '', date_time: [], status: 'ALL', page:1, limit: 15 },
        this.getList()
      }
      
    }
  }
</script>

<style lang="scss">
.v_user_list {
  .v-user-money-item-edit {
    span {
      font-size: 14px;
    }
    i {
      cursor: pointer;
      padding: 0 20px;
      font-size: 16px;
    }
  }
}

</style>